import PropTypes from 'prop-types';
import React from 'react';

import * as customPropTypes from '@/custom-prop-types';
import { Button } from '@/design-system/atoms/Button';
import { ContentContainer } from '@/design-system/atoms/ContentContainer';
import { Text } from '@/design-system/atoms/Text';
import { Theme } from '@/design-system/atoms/Theme';
import { Title } from '@/design-system/atoms/Title';

import styles from './RelatedList.module.scss';

/**
The Related List component is a brief list of text links for displaying related content. Can be used as a stand alone banner or as a widget within the Card & Text Component.
 **/
const RelatedList = ({ id, title, listItems, ...props }) => {
    const splitValue = listItems?.length > 0 ? Math.round(listItems.length / 2) : 0;
    const columnOne = listItems?.slice(0, splitValue);
    const columnTwo = listItems?.slice(splitValue);
    return (
        <div className={styles[`related-list`]} {...props}>
            <ContentContainer id={id}>
                <Theme
                    name={Theme.NAME.TREATMENTS_AND_SERVICES}
                    className={styles[`related-list__wrapper`]}
                >
                    <>
                        {title && (
                            <Title
                                tag={Title.TAG.H3}
                                variant={Title.VARIANT.H6}
                                content={title}
                                className={styles[`related-list__title`]}
                            />
                        )}
                        <div role="list" className={styles[`related-list__list`]}>
                            <div
                                className={styles[`related-list__list-column`]}
                                role="presentation"
                            >
                                {columnOne?.map((item, index) => (
                                    <li key={index} className={styles[`related-list__list-item`]}>
                                        {item.href ? (
                                            <Button
                                                {...item}
                                                buttonStyle={Button.STYLE.TEXT}
                                                className={styles[`related-list__link`]}
                                                analytics={{
                                                    componentName: 'Related List',
                                                    componentTitle: title,
                                                    interactionType: 'internal_link',
                                                }}
                                            />
                                        ) : (
                                            <Text
                                                tag="p"
                                                variant={Text.VARIANT.CTA1}
                                                content={item.label}
                                            />
                                        )}
                                    </li>
                                ))}
                            </div>
                            <div
                                className={styles[`related-list__list-column`]}
                                role="presentation"
                            >
                                {columnTwo?.map((item, index) => (
                                    <li key={index} className={styles[`related-list__list-item`]}>
                                        {item.href ? (
                                            <Button
                                                {...item}
                                                buttonStyle={Button.STYLE.TEXT}
                                                className={styles[`related-list__link`]}
                                                analytics={{
                                                    componentName: 'Related List',
                                                    componentTitle: title,
                                                    interactionType: 'internal_link',
                                                }}
                                            />
                                        ) : (
                                            <Text
                                                tag="p"
                                                variant={Text.VARIANT.CTA1}
                                                content={item.label}
                                            />
                                        )}
                                    </li>
                                ))}
                            </div>
                        </div>
                    </>
                </Theme>
            </ContentContainer>
        </div>
    );
};

RelatedList.propTypes = {
    /**
     * Title, Optional
     */
    title: PropTypes.string,
    /**
     * Array of List Items
     */
    listItems: PropTypes.arrayOf(customPropTypes.linkPropType).isRequired,
};

export default RelatedList;
